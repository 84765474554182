import React from 'react';

import Layout from '../components/layout';
import Header from '../components/header';
import Nav from '../components/nav';
import Footer from '../components/footer';
import Contact from '../components/contact';
import SEO from '../components/seo';

import '../scss/attorno.scss';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Mobiel pizzakraam | Pizzafeest | Pizza op locatie"
      description="Attorno staat garant voor een evenement dat bijzonder origineel is
      en de nodige klasse naar de gasten toe uitstraalt. Onze mobiele
      houtovens hebben een stenen vloer waardoor pizza’s kunnen gebakken
      worden met een overheerlijke krokante bodem."
    />

    <Nav />
    <Header />

    <section
      className="pt-8 pb-5 text-center zero-bottom pattern-bg"
      style={{ backgroundImage: 'url(/design/img/pattern-background.png)' }}
    >
      <div className="container">
        <div className="row no-dev">
          <div className="col-sm-8 col-sm-offset-2 sep-bottom editContent">
            <h2 className="title">Een origineel feest organiseren?</h2>
            <p>
              Wij kunnen U garanderen dat wij er alles aan doen om uw feest een
              zeer bijzonder cachet te geven. Onze mobiele Italiaanse
              houtoven(s) met stenen vloer, zorgen voor pizza’s met de typisch
              dunne, krokante bodem zoals die bij de betere Italiaanse
              gelegenheden opgediend wordt. De traditionele houtoven zorgt niet
              alleen voor de authentieke smaak van de pizza’s maar draagt
              bovendien bij aan de gezellige sfeer en maakt dat, in combinatie
              met de vriendelijkheid en professionaliteit van ons personeel, dat
              uw verjaardagsfeest, huwelijksfeest, communiefeest,
              personeelsfeest, bedrijfsfeest… het succes kent dat het verdient.
            </p>
          </div>
          <div className="col-sm-12">
            <img
              src="/design/img/center-block-background.png"
              className="screen"
              alt="Onze authentieke oven"
            />
          </div>
        </div>
      </div>
    </section>
    <Contact />

    <Footer />
  </Layout>
);

export default IndexPage;
