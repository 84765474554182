import React from 'react';

import Slogan from '../components/slogan';
import Teaser from '../components/teaser';

const Header = () => (
    <header
        className="intro-block pt-5 pb-8 cover-bg"
        style={{
            backgroundImage: `url(
                'design/img/header/header-pizza-dienblad-donker.jpg'
            )`,
        }}
    >
        <div className=" container ">
            <Slogan />
            <Teaser />
        </div>
    </header>
);

export default Header;
